@import '~/bootstrap/scss/bootstrap.scss';
@import '~/react-toastify/dist/ReactToastify.min.css';

html,
body {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.h-80px {
  height: 80px;
}
.overflow-xh {
  overflow-x: hidden !important;
}
.overflow-yh {
  overflow-y: hidden !important;
}
#chat-wrapper {
  min-width: 320px;
}
#bg-pattern {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #fff;
    opacity: 0.4;
  }
}
