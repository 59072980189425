.message-body {
  background-color: #5c636a;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  max-width: 80%;
  text-align: left;
}

.message-owner {
  background-color: #0b5ed7;
}
